
<template>
  <div id="app">
    <nav class="navbar navbar-expand-lg navbar-light bg-light header-bg">
      <div class="container-fluid px-100">
        <a class="navbar-brand" href="#"> <img :src="logoSrc" class="w-100"></a>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse"
          data-bs-target="#navbarNav" aria-controls="navbarNav"
          aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarNav">
          <ul class="navbar-nav ms-auto">
            <li class="nav-item">
              <a class="nav-link px-3" href="#" data-bs-toggle="modal" data-bs-target="#securityModal">Security</a>
            </li>
            <li class="nav-item">
              <a class="nav-link px-3" href="#" data-bs-toggle="modal" data-bs-target="#carrerModal">Careers</a>
            </li>
            <li class="nav-item">
              <a class="nav-link px-3" href="#" data-bs-toggle="modal" data-bs-target="#contactModal" @click="showSuccessMessage = false">Contact Us</a>
            </li>
          </ul>
        </div>
      </div>
    </nav>

    <section class="main-banner hero">
      <div class="container-fluid px-100 p-10">
        <h1 class="heading-main">Post-Acute Care. <br> Made Simple.</h1>
        <p class="p-main">Unleash the potential of Gen AI to make better post-acute <br> care decisions.</p>
        <div>
    <!-- Other Vue component content -->
    <a href="#" class="btn btn-primary btn-custom" @click.prevent="openCalendlyPopup">Request a Demo</a>
    <div ref="calendlyEmbedContainer" class="calendly-embed-container"></div>
  </div>
      </div>
      <span class="curve"></span>
    </section>
    <footer class="footer-index">
      <div class="container-fluid text-end mt-6">
        <span class="footer-rights">© 2024 Bridge all rights reserved</span>
      </div>
    </footer>

    


    <!-- Security Modal -->
<div class="modal fade" id="securityModal" tabindex="-1" aria-labelledby="securityModalLabel" aria-hidden="true">
  <div class="modal-dialog">
      <div class="modal-content">
          <div class="modal-header">
              <h5 class="modal-title" id="securityModalLabel">Security</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body mb-4 pt-0">
              <p class="security-txt pb-3">We take security at <span class="theme-cl">Bridge</span> seriously. Please contact us for more information on our security and compliance.</p>
              <div class="row align-items-center justify-content-center">
                <div class="col-6 d-flex justify-content-center align-items-center privacy-pol">
                  <a href="./HIPAA_Privacy_Notice.pdf" target="_blank" class="link">Privacy Policy</a>
                </div>
                <div class="col-6 d-flex justify-content-center align-items-center br">
                    <img :src="sec"  alt="" class="w-75">
                </div>
            </div>
            
            
          </div>
      </div>
  </div>
</div>


    <!-- Carreers Modal -->
    <div class="modal fade" id="carrerModal" tabindex="-1" aria-labelledby="carrerModalLabel" aria-hidden="true">
      <div class="modal-dialog">
          <div class="modal-content">
              <div class="modal-header">
                  <h5 class="modal-title" id="carrerModalLabel">Careers</h5>
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body pt-0">
                  <p class="security-txt">We are continuously seeking compassionate, highly skilled, and forward-thinking individuals who are passionate about leveraging AI to revolutionize post-acute healthcare. If you are interested in exploring joining the Bridge team, please send us your CV and a note to <a href="mailto:info@bridgesocialcare.com" class="theme-cl">info@bridgesocialcare.com</a></p>
              </div>
          </div>
      </div>
    </div>

      <!-- Contact Us Modal -->
          <div class="modal fade" id="contactModal" tabindex="-1" aria-labelledby="contactModalLabel" aria-hidden="true">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="contactModalLabel">Contact Us</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body mb-2 pt-0">
                <p class="security-txt pb-2">We always love hearing from providers and families<span class="theme-cl"> Contact us below</span></p>
                <!-- Success Message -->
                <div v-if="showSuccessMessage" class="alert alert-success mt-3" role="alert">
                    Thanks! We'll contact you soon.
                  </div>
                <form @submit.prevent="submitForm">
                    <div class="row">
                      <div class="col-6 mb-2">
                        <label class="label">First Name</label>
                        <input type="text" v-model="firstName" class="form-control" placeholder="John">
                      </div>
                      <div class="col-6 mb-2">
                        <label class="label">Surname</label>
                        <input type="text" v-model="surName" class="form-control" placeholder="Doe">
                      </div>
                      <div class="col-6 mb-4">
                        <label class="label">Email*</label>
                        <input type="email" v-model="email" class="form-control" placeholder="Johndoe@gmail.com" required>
                      </div>
                      <div class="col-12 mb-4">
                        <div class="custom-checkbox">
                          <input type="checkbox" v-model="subscribe" id="checkbox1">
                          <label for="checkbox1">Yes, I would like to receive future product updates by email.</label>
                      </div>
                      </div>
                      <div class="col-12">
                        <button type="submit" class="btn btn-primary w-100 btn-submit">Submit</button>
                      </div>
                    </div>
                  </form>
                </div>
            </div>
        </div>
      </div>

      
      </div>  
</template>



<script>
import axios from 'axios';

export default {
  data() {
    return {
      logoSrc: require('@/assets/img/logo.png'),
      sec: require('@/assets/img/sec.png'),
      showPopup: false,
      firstName: '',
      surName: '',
      email: '',
      subscribe: false,
      showSuccessMessage: false
    };
  },
  methods: {
    openCalendlyPopup() {
      if (window.Calendly) {
        window.Calendly.initPopupWidget({
          url: 'https://calendly.com/carloross/book-a-demo?hide_event_type_details=1&hide_gdpr_banner=1'
        });
      }
    },
    async submitForm() {
      try {
        const response = await axios.post('https://formspree.io/f/mnnaqzgq', {
          firstName: this.firstName,
          surName: this.surName,
          email: this.email,
          subscribe: this.subscribe
        });
        // Show success message
        this.showSuccessMessage = true;
      } catch (error) {
        alert('Error sending email: ' + error.message);
      }
      this.firstName = '';
      this.surName = '';
      this.email = '';
      this.subscribe = false;
    }
  },
  mounted() {
    // Load Calendly script dynamically
    const script = document.createElement('script');
    script.src = 'https://assets.calendly.com/assets/external/widget.js';
    script.async = true;
    document.body.appendChild(script);

    // Include Calendly widget CSS
    const cssLink = document.createElement('link');
    cssLink.href = 'https://assets.calendly.com/assets/external/widget.css';
    cssLink.rel = 'stylesheet';
    document.head.appendChild(cssLink);
  }
};
</script>


<style>
#app {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  margin: 0;
}
.link{
  color: #31A28E;
    text-decoration: none;
}
.link:hover{
  color: #4dd6bd;
    text-decoration: none;
}

</style>

